import { Injectable } from "@angular/core";
import Intercom, {
  update as IntercomUpdate,
  shutdown as IntercomShutdown,
  show as IntercomShow,
} from "@intercom/messenger-js-sdk";
import { environment } from "../../../environments/environment";
import { Environment } from "../../../environments/environment.type";
import { getLogger } from "../utils/get-logger";
import { AuthenticationService } from "./api/authentication.service";
import { LanguageService } from "./language.service";

@Injectable({ providedIn: "root" })
export class IntercomService {
  private readonly logger = getLogger("IntercomService");
  private config?: Environment["intercom"] = environment.intercom;
  private isInitialized = false;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly languageService: LanguageService,
  ) {
    this.languageService.changed$.subscribe(({ language }) => {
      if (this.isEnabled()) {
        IntercomUpdate({ language_override: language });
      }
    });

    this.authService.logout$.subscribe(() => {
      if (this.isInitialized) {
        IntercomShutdown();
        this.isInitialized = false;
      }
    });
  }

  public async init(): Promise<void> {
    if (!this.isEnabled()) {
      this.logger.info("Skipping initialization.");
      return;
    }

    try {
      Intercom({
        app_id: this.config!.appId,
        app_base: this.config!.appBase,
        language_override: this.languageService.language,
        user_id: this.authService.userId
          ? String(this.authService.userId)
          : undefined,
        created_at: this.authService.userCreatedAt,
        email: this.authService.userEmail,
        user_hash: this.authService.userIntercomHash,
      });

      this.isInitialized = true;
    } catch (err) {
      this.logger.error("Failed to initialize Intercom", err);
    }
  }

  public isEnabled(): boolean {
    const isPartner = this.authService.isRolePartner();
    const isFeatureEnabled =
      environment.features.intercom?.userIds?.includes(
        this.authService.userId ?? -1,
      ) ?? true;

    return !!this.config && isPartner && isFeatureEnabled;
  }

  public show(): void {
    if (this.isEnabled()) {
      IntercomShow();
    }
  }
}
